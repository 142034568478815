import React, { useEffect, useState, useRef, useCallback } from "react";
import Footer from "./footer";
import aristotle_and from "../images/Newsletter/aristotle_and.jpg";
import bg1 from "../images/Newsletter/bg1.jpg";
import bg2 from "../images/Newsletter/bg2.jpg";
import bg3 from "../images/Newsletter/bg3.jpg";
import bg4 from "../images/Newsletter/bg4.jpg";
import study from "../images/Newsletter/study.jpg";
import './animation.css';
import newton from "../images/Newsletter/newtonpainting.jpg"
import Crossword from '@jaredreisinger/react-crossword';
import confetti from 'canvas-confetti';
import { useMemo } from 'react';

function NewsletterJan() {
  const crosswordRef = useRef(null);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const [endTime, setEndTime] = useState(null);
  const [confettiTriggered, setConfettiTriggered] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const displayTimeRef = useRef("0m 0s");
  const [showPopup, setShowPopup] = useState(false);
  const [finishedTime, setFinishedTime] = useState("");


  const data = useMemo(() => ({
      across: {
        2: {
          clue: 'As part of its ambitious space exploration endeavors, India launched its first crewed space mission, aptly named after the Sanskrit word meaning “celestial craft.” What was this historic milestone in India’s journey to the stars called?',
          answer: 'GAGANYAAN',
          row: 1,
          col: 0,
        },
        4: {
          clue: 'When scattered light waves are tamed to vibrate in a single plane – like unruly hair being combed neatly in one direction – what “P” word describes this organizing process used in sunglasses and photography filters?',
          answer: 'POLARIZATION',
          row: 6,
          col: 4,
        },
        5: {
          clue: 'When scientists study how heat propagates through solids, they describe the process in terms of quantized vibrations that transfer thermal energy. What are these tiny, heat-carrying particles called?',
          answer: 'PHONONS',
          row: 8,
          col: 3,
        },
        6: {
          clue: 'When atoms become so energetic that their electrons break free, creating an electrically charged state of matter beyond solid, liquid, and gas – what’s this fourth state that makes up stars and lightning?',
          answer: 'PLASMA',
          row: 10,
          col: 4,
        },
        8: {
          clue: 'When sunlight interacts with water droplets in the atmosphere, it creates a natural phenomenon that paints the sky in vivid hues. What geometric shape dominates the curve of this colorful arc?',
          answer: 'CIRCLE',
          row: 15,
          col: 4,
        },
        10: {
          clue: 'In the study of wave phenomena, there is a term used to describe the shift of a wave’s frequency toward the lower end of the spectrum, often observed in astronomy. What is this term?',
          answer: 'REDSHIFT',
          row: 17,
          col: 7,
        },
      },
      down: {
        1: {
          clue: 'In the world of measuring light intensity, there’s a unit named after a humble lighting source that’s been illuminating homes since ancient times and still tops birthday cakes today. What’s this fundamental unit?',
          answer: 'CANDELA',
          row: 0,
          col: 7,
        },
        3: {
          clue: 'Solar cells harness sunlight and transform it into electricity, enabled by a principle discovered through the study of light’s particle nature. What is this phenomenon called?',
          answer: 'PHOTOELECTRIC',
          row: 4,
          col: 5,
        },
        7: {
          clue: 'Shuji Nakamura, a Nobel laureate, changed the way we illuminate the world with LEDs that emit light in a short-wavelength color. What color is this innovation famously associated with?',
          answer: 'BLUE',
          row: 14,
          col: 8,
        },
        9: {
          clue: 'In spectroscopy, to measure how “wide” or spread out a spectral line is, scientists measure its entire breadth at 50 percent below its peak. What is the four-letter acronym for this measurement?',
          answer: 'FWHM',
          row: 15,
          col: 11,
        },
      },

    
}), []);

const customTheme = useMemo(() => ({
  gridBackground: 'rgba(33, 33, 99, 0.0)',
  cellBackground: 'rgba(255, 255, 255, 0.8)',
  cellBorder: 'rgba(86, 81, 81, 0.5)',
  textColor: '#000000',
  numberColor: '#A78BFA',
  focusBackground: '#6366F1',
  highlightBackground: '#A78BFA',
}), []);

  // Start the timer when the component mounts
  const startTimer = () => {
    if (!timerStarted) {
      setTimerStarted(true);
      startTimeRef.current = Date.now();
      timerRef.current = setInterval(() => {
        const elapsed = Date.now() - startTimeRef.current;
        displayTimeRef.current = formatTime(elapsed);
  
        // Update the displayed time without causing re-renders
        const timeElement = document.getElementById("display-time");
        if (timeElement) {
          timeElement.textContent = displayTimeRef.current;
        }
      }, 1000);
    }
  };

  const handleAllCorrect = () => {
    if (!confettiTriggered) {
      const completionTime = Date.now() - startTimeRef.current;
      setEndTime(completionTime);
      setFinishedTime(formatTime(completionTime)); // Set the finished time
      setConfettiTriggered(true);
      setShowPopup(true); // Show the popup
      clearInterval(timerRef.current);
      confetti({
        particleCount: 200,
        spread: 120,
        origin: { y: 0.5 },
        colors: ["#6366F1", "#3B82F6", "#A78BFA"],
      });
    }
  };

  const handleCrosswordCorrect = () => {
    if (crosswordRef.current.isCrosswordCorrect()) {
      handleAllCorrect();
    }
  };

  const handleCellChange = useCallback(() => {
    if (!timerStarted) {
      startTimer();
    }
  }, [timerStarted]);

  const formatTime = (timeInMs) => {
    const seconds = Math.floor((timeInMs / 1000) % 60);
    const minutes = Math.floor((timeInMs / 60000) % 60);
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current); // Cleanup timer on component unmount
  }, []);


  useEffect(() => {
    // Wait for the component to render, then find the dynamically generated div
    const targetElement = document.querySelector(".drWDDE, .ksBajo");
    if (targetElement) {
      targetElement.classList.add("custom-width");
    }
  }, []);

  const [background, setBackground] = useState(bg1); // Initial background
  const [isSidebarFixed, setSidebarFixed] = useState(true);
  
  // References to sections
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const sidebarRef = useRef(null);
  const sectionendRef = useRef(null);
  const sidebarContainerRef = useRef(null);
  // Preloading the images
  const preloadImages = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image; // Preload image by setting the src
    });
  };

  useEffect(() => {
    const images = [bg1, bg2, bg3, bg4]; // List of images to preload
    preloadImages(images); // Call preload function to load the images
  }, []);

  const handleScroll = useCallback(() => {
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;
    const section3 = section3Ref.current;
    const section4 = section4Ref.current;
    


    if (section1 && section2 && section3 && section4) {
      const section1Top = section1.getBoundingClientRect().top;
      const section2Top = section2.getBoundingClientRect().top;
      const section3Top = section3.getBoundingClientRect().top;
      const section4Top = section4.getBoundingClientRect().top;

      // Smooth background image change using requestAnimationFrame
      const bgChange = () => {
        if (section1Top <= window.innerHeight / 2 && section1Top >= -window.innerHeight / 2) {
          setBackground(bg1);
        } else if (section2Top <= window.innerHeight / 2 && section2Top >= -window.innerHeight / 2) {
          setBackground(bg2);
        } else if (section3Top <= window.innerHeight / 2 && section3Top >= -window.innerHeight / 2) {
          setBackground(bg3);
        } else if (section4Top <= window.innerHeight / 2 && section4Top >= -window.innerHeight / 2) {
          setBackground(bg4);
        }
      };

      // Call the bgChange function inside requestAnimationFrame
      requestAnimationFrame(bgChange);
    }
  }, []);

  const scrollToSection = (ref) => {
      ref.current.scrollIntoView({ behavior: "smooth" });
    };
  

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setSidebarFixed(false); // Stick the sidebar at the top of the footer
            } else {
              setSidebarFixed(true); // Sidebar remains fixed
            }
          },
          {
            root: null,
            threshold: 0.1,
          }
        );
    
        if (sectionendRef.current) {
          observer.observe(sectionendRef.current);
        }
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
          if (sectionendRef.current) {
            observer.disconnect();
          }
        };
      }, [handleScroll]);

  return (
    <div className="bg-black font-defmono text-white w-full min-h-screen overflow-hidden relative">
      {/* Blurred Background Image */}
      <div
        className="absolute inset-0 z-0 h-full w-full overflow-hidden"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          filter: "blur(5px)",
          opacity: "0.2",
          transition: "background-image 1s ease-in-out",
        }}
      />

      {/* Sidebar Index */}
      <div
        ref={sidebarContainerRef}
        className={`${
          isSidebarFixed ? "fixed top-20" : "absolute bottom-20" 
        } right-4 z-30 w-[18%] mobiled-hidden tabletd-hidden bg-black/[0.5] rounded-lg p-4 shadow-lg mt-[65px] backdrop-blur-md transition-all duration-300`}
        style={isSidebarFixed ? {} : { position: "absolute", top: "auto", bottom: "480px" }}
      >
        
        <h3 className="text-xl font-bold mb-4 text-center">Sections</h3>
        <ul className="space-y-2">
          <li>
            <button
              onClick={() => scrollToSection(section1Ref)}
              className="text-white hover:underline"
            >
              History of Optics 1
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection(section2Ref)}
              className="text-white hover:underline"
            >
              History of Optics 2
            </button>
          </li>
           <li>
            <button
              onClick={() => scrollToSection(section3Ref)}
              className="text-white hover:underline"
            >
              History of Optics 3
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection(section4Ref)}
              className="text-white hover:underline"
            >
              Crossword
            </button>
          </li>
          {/*<li>jiejfiefje==fjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li> */}
          
        </ul>
      </div>

      <div
        ref={sidebarContainerRef}
        className={`${
          isSidebarFixed ? "fixed top-20" : "absolute bottom-20" 
        } left-4 z-30 w-[18%] bg-black/[0.5] mobiled-hidden hidden tabletd-hidden rounded-lg p-4 shadow-lg mt-[65px] backdrop-blur-md transition-all duration-300`}
        style={isSidebarFixed ? {} : { position: "absolute", top: "auto", bottom: "480px" }}
      >
        
        <h3 className="text-xl font-bold mb-4 text-center">Sections</h3>
        <ul className="space-y-2">
          <li>
            <button
              onClick={() => scrollToSection(section1Ref)}
              className="text-white hover:underline"
            >
              Section 1
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection(section2Ref)}
              className="text-white hover:underline"
            >
              Section 2
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection(section3Ref)}
              className="text-white hover:underline"
            >
              Section 3
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection(section4Ref)}
              className="text-white hover:underline"
            >
              Section 4
            </button>
          </li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          <li>jiejfiefjefjh</li>
          
        </ul>
      </div>

      {/* Newsletter Section */}
      <div className="relative z-10 flex justify-center items-center py-12">
        <div className="w-full max-w-4xl pcd:max-w-[60%] px-2">
          {/* Section 1 */}
          <div ref={section1Ref} className="mb-16">
            <div className="bg-black/[.4] p-8 rounded-lg shadow-lg backdrop-blur-[30px]">
              <h2 className="text-3xl font-bold font-montserrat text-justify mb-6 text-left text-white">
                HISTORY OF OPTICS
              </h2>
              <div className="relative flex justify-center mb-6 overflow-hidden rounded-lg">
                <div
                  className="absolute inset-0 bg-gradient-to-br from-transparent to-transparent rounded-lg blur-lg z-[-1]"
                  style={{
                    background: `linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0.4)), url(${aristotle_and})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <img
                  src={aristotle_and}
                  alt="Aristotle"
                  className="w-full h-[500px] mobiled:h-[200px] object-cover rounded-lg shadow-md"
                />
              </div>
              <div className="space-y-4 text-lg leading-[2rem] font-montserrat text-justify">
                <p>
                  <strong > Romanticized Eureka Moments to Medieval Cautionary Tales</strong>
                </p>
                <p>
                  Modern science stories are like comforting bedtime tales—Newton's apple falling dramatically, Einstein scribbling equations in a moment of genius. Even though these stories are all far from the truth, they are soothing to hear and make the pursuit of science very appealing.
                </p>
                <p>
                  But dig a little deeper into ancient scientific lore, and you'll find stories that are less "inspiring breakthrough", more "medieval cautionary nightmare." Take the wild tale of Aristotle and Phyllis—a story so bonkers it sounds like a soap opera plot rather than a philosophical anecdote. In this medieval woodcut-worthy narrative, Phyllis supposedly humiliates Aristotle by literally leading him around on a leash while he's in animal-like submission.
                </p>
                <p>
                  <strong > The Wild Tale of Aristotle and Phyllis: A Soap Opera Plot</strong>
                </p>
                <p>
                  The story goes that when Aristotle warned his student Alexander the Great about getting too distracted by his lover Phyllis, she hatched a revenge plan. She seduced the great philosopher, proving that even brilliant minds can be led astray—literally and figuratively.
                </p>
                <p>
                  Intellectual Curiosity? Beware the Medieval Propaganda! But here's the kicker: This story isn't some quirky historical footnote. It was deliberately crafted by a medieval cleric named Jacques de Vitry as a warning against intellectual pursuit. Think about that—instead of celebrating scientific curiosity, they were manufacturing stories designed to discourage people from thinking too deeply.
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-4">  
                  <div className="flex flex-col font-montserrat justify-center ">
                    <p className="text-lg leading-[2rem]">
                      <strong > Modern Myths vs. Medieval Morals</strong>
                    </p>
                    <img
                      src={study}
                      alt="Aristotle"
                      className="w-full pcd:hidden tabletd:visible h-[300px] object-cover rounded-lg shadow-md"
                    />
                    <div className="justify-end">
                    <p className="text-lg leading-[2rem] font-montserrat text-justify text-justify">
                        This is especially sad when we consider how easy it would have been to confer
                        Aristotle's theories with a deliciously dramatic backstory. For instance, let us
                        consider how Aristotle rebutted Plato’s theory of vision. Plato believed that we
                        see objects when an invisible fire emitted by the eye touches the objects around
                        us. Aristotle argued against this belief by observing that one can see small
                        versions of the objects around us in another individual's eyes. One could have
                        easily fabricated a story where Aristotle's inspiration for this rebuttal came in a
                        moment of intimacy wherein he stared into his lover's eyes.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex justify-center items-center overflow-hidden rounded-lg">
                    <img
                      src={study}
                      alt="Aristotle"
                      className="w-full h-[300px] tabletd:hidden object-cover rounded-lg shadow-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-[300px,1fr] gap-4">
                  {/* Left Column: Image */}
                  <div className="relative flex justify-center items-center overflow-hidden rounded-lg">
                    <img
                      src={newton}
                      alt="Aristotle"
                      className="w-full h-[300px] object-cover rounded-lg shadow-md"
                    />
                  </div>
                  {/* Right Column: Text */}
                  <div className="flex flex-col justify-center">
                    <p className="text-lg font-montserrat  leading-[2rem] text-justify">
                      It seems only fair that the Ancient Greeks and Romans have such comforting bedtime tales written
                       about their discoveries; this column will attempt to do the same. We shall tell the story of how
                        ancient Wisdom came to be in a setting not so different from a typical high school drama so that
                       critical thought is reduced from a Himalayan monstrosity to a sweet bedtime tale.
                    </p>
                  </div>
                </div>
              
            </div>
            
          </div>


          {/* Section 2 */}
          <div ref={section2Ref} className="mb-16">
            <div className="bg-black/[.4] p-8 rounded-lg shadow-lg backdrop-blur-[30px]">
              <h2 className="text-3xl font-bold font-montserrat text-justify  text-left text-white">
                History of Optics
              </h2>
              {/* <div className="relative flex justify-center mb-6 overflow-hidden rounded-lg">
                <div
                  className="absolute inset-0 bg-gradient-to-br from-transparent to-transparent rounded-lg blur-lg z-[-1]"
                  style={{
                    background: `linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0.4)), url(${study})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                
              </div> */}
              <div className="text-lg leading-[2rem] font-montserrat text-justify text-white space-y-4">
                
            
      
                <div className="grid grid-cols-1 md:grid-cols-[auto,1fr] gap-4">
                  {/* Image Column */}

                  {/* <div className="relative flex  justify-center items-center overflow-hidden rounded-lg">
                    <div
                      className="absolute inset-0 rounded-lg blur-lg z-[-1]"
                      style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0.4)), url(${aristotle_and})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  
                  </div> */}
                  {/* Text Column */}
                  
                </div>
                <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-4">
                  {/* Left Column: Image */}
                  
                  {/* Right Column: Text */}
                  <div className="flex flex-col justify-center p-4">
                    <p>
                      <strong > Modern Myths vs. Medieval Morals</strong>
                    </p>
                    <img
                      src={study}
                      alt="Aristotle"
                      className="w-full pcd:hidden tabletd:visible h-[300px] object-cover rounded-lg shadow-md"
                    />
                    <div className="justify-end">
                    <p className="text-lg leading-[2rem] font-montserrat text-justify text-justify">
                        This is especially sad when we consider how easy it would have been to confer
                        Aristotle's theories with a deliciously dramatic backstory. For instance, let us
                        consider how Aristotle rebutted Plato’s theory of vision. Plato believed that we
                        see objects when an invisible fire emitted by the eye touches the objects around
                        us. Aristotle argued against this belief by observing that one can see small
                        versions of the objects around us in another individual's eyes. One could have
                        easily fabricated a story where Aristotle's inspiration for this rebuttal came in a
                        moment of intimacy wherein he stared into his lover's eyes.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex justify-center items-center overflow-hidden rounded-lg">
                    <img
                      src={study}
                      alt="Aristotle"
                      className="w-full h-[300px] tabletd:hidden object-cover rounded-lg shadow-md"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-[300px,1fr] gap-4">
                  {/* Left Column: Image */}
                  <div className="relative flex justify-center items-center overflow-hidden rounded-lg">
                    <img
                      src={newton}
                      alt="Aristotle"
                      className="w-full h-[300px] object-cover rounded-lg shadow-md"
                    />
                  </div>
                  {/* Right Column: Text */}
                  <div className="flex flex-col justify-center p-4">
                    <p className="text-lg leading-[2rem] text-justify">
                      It seems only fair that the Ancient Greeks and Romans have such comforting bedtime tales written
                       about their discoveries; this column will attempt to do the same. We shall tell the story of how
                        ancient Wisdom came to be in a setting not so different from a typical high school drama so that
                       critical thought is reduced from a Himalayan monstrosity to a sweet bedtime tale.
                    </p>
                  </div>
                </div>

                  {/* Text Column */}


              </div>
            </div>
          </div>


          {/* Section 3 */}
          <div ref={section3Ref} className="mb-16">
          <div className="bg-black/[.4] p-8 rounded-lg shadow-lg backdrop-blur-[30px] grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Left Column: Text */}
              <div className="flex flex-col justify-center">
                <h2 className="text-3xl font-bold font-montserrat text-justify mb-6 text-white underline decoration-2 underline-offset-8">
                  History of Optics 3
                </h2>
                <p className="leading-relaxed text-lg font-montserrat text-justify">
                  Modern science stories are like comforting bedtime tales—Newton's apple
                  falling dramatically, Einstein scribbling equations in a moment of genius.
                  Even though these stories are all far from the truth, they are soothing to
                  hear and make the pursuit of science very appealing. But dig a little deeper
                  into ancient scientific lore, and you'll find stories that are less "inspiring
                  breakthrough", more "medieval cautionary nightmare."Modern science stories are like comforting bedtime tales—Newton's apple
                  falling dramatically, Einstein scribbling equations in a moment of genius.
                  Even though these stories are all far from the truth, they are soothing to
                  hear and make the pursuit of science very appealing. But dig a little deeper
                  into ancient scientific lore, and you'll find stories that are less "inspiring
                  breakthrough", more "medieval cautionary nightmare."
                </p>
              </div>
              {/* Right Column: Image */}
              <div className="relative flex justify-center items-center overflow-hidden rounded-lg">
                <div
                  className="absolute inset-0 rounded-lg blur-lg z-[-1]"
                  style={{
                    background: `linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0.4)), url(${aristotle_and})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <img
                  src={aristotle_and}
                  alt="Aristotle"
                  className="w-full h-[500px] object-cover rounded-lg shadow-md"
                />
              </div>
            </div>
          </div>



          {/* Section 4 */}
          <div ref={section4Ref} className="mb-16">
            <div className=" p-4 rounded-lg shadow-lg backdrop-blur-[30px]">
              <div className="bg-black/[.0] justify-self-center justify-content-center rounded-lg shadow-lg w-[90%] max-w-[600px]">
                <h2>Timer: <span id="display-time">{displayTimeRef.current}</span></h2>
                {showPopup && (
                  <div className="fixed inset-0 bg-black/50 flex items-top justify-center z-50 pt-[20vh]">
                    <div className="bg-black/[.5] rounded-lg shadow-lg text-center max-h-[150px] max-w-[400px]">
                      <h2 className="text-xl font-semibold mb-4">Congratulations!</h2>
                      <p>You completed the crossword in <strong>{finishedTime}</strong>.</p>
                      <button
                        className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={() => setShowPopup(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
                <div className="crossword-wrapper">
                  <Crossword
                    ref={crosswordRef}
                    data={data}
                    theme={customTheme}
                    onCrosswordCorrect={handleCrosswordCorrect}
                    onCellChange={handleCellChange}
                  />
                </div>
                <button
                  className="mt-4 bg-red-500 text-white py-2 px-4 justify-self-center mx-auto rounded relative hover:bg-red-600"
                  onClick={() => crosswordRef.current?.reset()}
                >
                  Clear Answers
                </button>
              </div>
            </div>
          </div>

          {/* Section End Marker */}
          <div ref={sectionendRef}></div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="relative z-10">
        <Footer />
      </div>
    </div>
  );
}

export default NewsletterJan;